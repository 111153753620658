@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');



.modal-confirm-content {
  padding-bottom: 20px;
  color: black !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  letter-spacing: 0.00938em; 
  padding-right: 25px;
}

.css-1dp65he-MuiSvgIcon-root{
  cursor: pointer;
}

.modal-confirm-button {
  padding-right: 25px !important;
}

.modal-confirm-title {
  white-space: pre-wrap;
}

.divAddEditCanal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;



  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  
  

 

  .autoComplete-icons-dropDown {
    width: 22px;
    height: 22px;
    color: #bfbfbf;
    cursor: text
  }
  .css-2rbg70-MuiPaper-root-MuiDialog-paper {
    height: 921px !important; 
    max-height: calc(100% ) !important;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 0%), 0px 24px 38px 3px rgb(0 0 0 / 0%), 0px 9px 46px 8px rgb(0 0 0 / 0%);
  }
  .autoComplete-icons-search {
    transform: rotate(180deg);
    width: 16px;
    height: 16px;
    color: #bfbfbf;
    cursor: text
  }

  .required-star {
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
  }

  .divLabel{
    margin:0.25em;
    height: 32px;
    position: relative;
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  .divLabel label{
    font-size: 1rem !important;
    font-family : 'Inter', sans-serif
  }
  .add-edit-label{
    font-size: 1rem !important;
    color:rgba(128, 128, 128, 0.658) !important;
  }

  input#Title {
    position: relative;
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    padding: 4px 11px;
    font-size: 13.8px;
    height: 47px !important;
  }


  .css-1s2513f-MuiAutocomplete-root {
    height: 52px !important ;
  }
  

  .css-1cwnky8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 46px !important;
  }
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    height: 50px !important ;
  }
  input#Description {
    position: relative;
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    
  }
  input#Quota {
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
    border-radius: 25px;
  }

  input#Upload_Path{
    position: relative;
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
  }

  input#Upload_Url {
    position: relative;
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
  }
  
  input#ReadingProtocol{
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
  }
  
  input#PermalienVideo{
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
  }

  input#EmbedVideo{
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
  }

  input#DefaultBitrateComputer{
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 13.8px;
    padding: 4px 11px;
    height: 47px !important;
  }
  input#DefaultBitrateTablet{
    border : 1px solid rgba(255, 255, 255, .5);
    height: 32px;
    font-size: 15.8px;
    padding: 4px 11px;
    height: 47px !important;
  }
p#server_Stream-helper-text{
  color: #ff4d4f;
  margin: 0px;
  font-size: 13.3px;
  position: absolute;
  top: 32px;
}

p#Description-helper-text{
  color: #ff4d4f;
  margin: 0px;
  font-size: 13.3px;
  position: absolute;
  top: 32px;
}

p#Upload_Url-helper-text{
  color: #ff4d4f;
  margin: 0px;
  font-size: 13.3px;
  position: absolute;
  top: 32px;
}

p#Title-helper-text {
  color: #ff4d4f;
  margin: 0px;
  font-size: 13.3px;
  position: absolute;
  top: 32px;
}

p#Upload_Path-helper-text{
  color: #ff4d4f;
  margin: 0px;
  font-size: 13.3px;
  position: absolute;
  top: 32px;
}


p#Quota-helper-text{
  color: #ff4d4f;
  margin: 0px;
  font-size: 13.3px;
  position: absolute;
  top: 32px;
}


input#Langue {
  height: 35px;
  font-size: 13.8px;
  padding: 0px 18px 0px 0px;
}

input#server_Stream {
  height: 33px;
  font-size: 13.8px;
  padding: 0px 18px 0px 0px !important;
}

  input#CDN {
    height: 33px;
    font-size: 13.8px;
    padding: 0px 18px 0px 0px;
  }

  input#mui-2 {
    height: 33px;
    font-size: 13.8px;
    padding: 0px 18px 0px 0px;
  }

  input#push_notification {
    height: 33px;
    font-size: 13.8px;
    padding: 0px 18px 0px 0px;
  }

  .Text-Field::placeholder {
    text-overflow: ellipsis;
  }


  .MuiAutocomplete-option {
    font-size: 13px;
  }
  .MuiAutocomplete-option:hover{
    background-color: rgb(245, 245, 245)!important;
  }

  .MuiAutocomplete-noOptions {
    height: 100px;
    background-image: url('./No_data.PNG');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
  }

  .MuiAutocomplete-paper{
    border-radius: 0px;
  }

  

  .card1 {
    padding: 24px 24px 24px 24px ;
    width: 100%;
    height: 100%;
    box-shadow: none !important;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--card_color) !important;
      z-index: 999;
      border: none !important;
      box-shadow: none !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--icon-color);
      /* color of the scroll thumb */
      border-radius: 20px;
      /* roundness of the scroll thumb */
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 2%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 2%;
    }
  }

  .divAddEditCanal input#Title {
    height: 41px;
  }
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .card2 {
    padding: 24px 24px 24px 24px ;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--card_color) !important;
      z-index: 999;
      border: none !important;
      box-shadow: none !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--icon-color);
      /* color of the scroll thumb */
      border-radius: 20px;
      /* roundness of the scroll thumb */
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }


  .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  

  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 13.5px;
    

    label {
      font-family: 'Inter', sans-serif !important;
      font-size: 15px;
      font-weight: 500 !important;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }



    @media screen and (max-width: 767px) {
      width: 100%;
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
    opacity: 1;
   }


  }

  .ant-col-22 {
    max-width: 100% !important;
  }

  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
    label{
      font-weight: 500 !important;
      color: black !important;
    }
    @media screen and (max-width: 1300px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      font-weight: bold;
    }
  }
  .InputLablDiv-multi{
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-top: 0;
    label{
      padding-bottom: 5px;
    }
    }

  .InputLablDivSelect {
    width: 100%;
    display: grid;
    margin-top: 5px;
    label{
      font-weight: 500 !important;
      color: black !important;
    }
    .formItem3:first-child {
      margin-right: 2%;
    }
    .css-ece9u5{
      width: 100%;
    }
    .MuiOutlinedInput-input{
      padding: 14px !important;
    }
  }

  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }

}



// css top  canalHeader  

.ant-breadcrumb {
  box-sizing: border-box;
  margin-left: 32px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

/* 
.datable_marginall {
  margin: 20px;
 
}
*/
 
  .lineActions{
    display: flex!important;
    flex-direction: row !important;
  }
  .lineActions-item{
    margin:0px 10px
  }
 
.bg_datatable {*
  .Breadcrumb{
    .AjouterText{
      @media screen and (max-width: 290px ) {
        position: initial;
      }
    }
    .HomeLink{color: rgba(0, 0, 0, 0.45);
      transition: color 0.3s;
    }
  }
  .MuiBreadcrumbs-root{
    font-size: 14px;
    margin: 0px 0px 0px 32px;
  }

  .MuiBreadcrumbs-separator{
    margin: 0px 8px;
    font-size: 17px;
  }
  .div_btn_add_cancel{
    .MuiButton-endIcon{
      @media screen and (max-width : 767px){margin: 0px;}
    }
    .iconForResponsive{
      display: none;
      @media screen and (max-width : 767px) {
        display: initial;
        font-size:18px !important;
        margin-left: auto;
        margin-right: auto;
        display: block !important;
      }
    }
    .btnAjouter{
      height: 44px;
      width: 99.5px;
      .MuiButton-startIcon{margin :0px;}
      @media screen and (max-width:767px) {
        width: min-content;
      }
    }
    .spn{
      font-Size:13.5px;
      font-weight: 400;
      text-transform: capitalize
    }
    .btn_backBlue{
          color: #fff;
           background: #1890ff;
    }
    .btnCancel{
               border-radius: 2px;
               color: rgba(0, 0, 0, 0.85);
               border-color: #d9d9d9;
               background: #fff;
    }
    
  }
  

}  

.divGlobalTable {
  margin: 10px;
  width: auto;
}

.canal_pointer {
  cursor: pointer;
}

legend {
  width: auto !important;
}
.css-wgai2y-MuiFormLabel-asterisk{
  color: rgb(255,0,0) !important;
}




.btnSubmit.btn_backBlue {
  height: 39px !important; 
  width: 99.5px  !important;
  margin-top: 2px !important ;
}

.onDelete {
  display: flex !important ;
  align-items: center  !important;
}
.onDelete > span{
  margin-top: 1rem;
  cursor: pointer;
}


.add_edit {
  margin: 12px !important ;
}

.row {
  margin:1em
}

.row_flex {
  margin:1em;
  display: flex;
  gap:5em;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller{
  overflow-x: hidden !important;
}
@media screen and (max-width:420px) {
  .css-1w5m2wr-MuiDataGrid-virtualScroller{
    overflow-x: scroll !important;
  }
}


.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  height: 123px !important;
}


.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 48px !important;
}



.css-q8hpuo-MuiFormControl-root {
  height: 62.63px !important;
}

.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  height: 57px !important;
  background: #FFFFFF !important;
  border: 1px solid #E0E0E0 !important ;
  border-radius: 6px !important ;
}

.css-1floue0-MuiInputBase-root-MuiOutlinedInput-root {
  background: #FFFFFF !important;
  border: 1px solid #E0E0E0 !important;
  border-radius: 6px !important;
  overflow: hidden !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  background: #FFFFFF !important;
  border-radius: 6px !important;
}


.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
  height: 103px !important ;
}







.MuiOutlinedInput-root {
  background: #FFFFFF !important ;
  border-radius: 6px !important ;
}
.bitinput-style{
  width: 100%;
  label{
    font-size: 16px !important;
    padding: 0 5px !important;
  }
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cellContent  {
  text-align: center !important;
}


.css-14b29qc {
  gap:1em !important
}

.detailsUserDiv_spn1 {
  font-family: "Inter", sans-serif !important;

}


.css-14b29qc {
  margin:0.5em !important;
}

.language-select {
  width: 100% !important;
}

.actionCanal {
    display: flex !important;
    flex-direction: row !important;
    width: 120px ;
    align-items: center !important;
    justify-content: space-between !important;
    div{
      font-size: 15px !important;
      .icon_action{
        cursor: pointer !important;
      }
    }
}

.rect_filterCanal {
  width: 100%;
  height: 40px;
  box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.12);
  border-radius: 4px;
  margin-top: 1rem;
}

.getToTheLetfLittle{
  margin-left: 1%;
}

.channels-details-modal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .channels-details-modal-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 1%;
    .channels-details-modal-title{
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
    .channels-details-modal-value{
      font-size: 14px;
      font-weight: 400;
      border-radius: 6px;
      height: auto;
      padding: 11px;
      border: 1px solid #e0e0e0;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}