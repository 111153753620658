.list_lives {
  width: 93px !important;
  height: 54px !important;
  position: relative;

  .ant-image-img {
    display: block;
    position: relative;
    width: 93px;
    height: 54px;
  }
}
  
.action{
  display: flex !important;
  flex-direction: row !important;
  width: 150px !important;
  align-items: center !important;
  justify-content: space-between !important;
  div{
    font-size: 15px !important;
    .icon_action{
      cursor: pointer !important;

    }
  }
  @media screen and (max-width: 600px) {
   width: 100% !important;
  }
}

.MuiMenu-list ,.MuiList-root, .MuiList-padding  {
  list-style: none  !important ;
    margin: 0  !important ;
    padding: 8px 8px  !important ;
    position: relative  !important ;   
    outline: 0  !important ;
    display: flex !important;
    flex-direction: row !important;
}
.ModalStyle .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(72, 75, 72, 0) !important;
}

.ModalStyle .css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 0), 0px 24px 38px 3px rgb(0 0 0 / 1%), 0px 9px 46px 8px rgb(0 0 0 / 0) !important;
}

.css-cwpu7v {
  box-shadow: 0 11px 15px -7px transparent, 0 0px 0px 0px rgba(0, 0, 0, .01), 0 9px 34px -6px transparent !important;
}

.ModalStyle .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.css-919eu4 {
  background-color: rgba(0, 0, 0, 0.5) !important;

}

.css-10ghrmp-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}

.css-1qvr50w-MuiTypography-root {
  font-weight: 500 !important;
  font-size: 19px !important;
}

.MuiDropzoneArea-icon {
  display: none !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  margin: 6px;
}

.MuiTypography-root {
  border-top: 10px !important;
}

.divGlobalTable {
  margin: 10px;
  width: auto;
}

.modal_export_row{
  display: grid !important;
  grid-template-columns: 20% 75% 5% !important;
  padding: 1em !important;
  gap: 0 5px !important;
}

.css-2b8pcz {
  padding: 0 !important;
  border-radius: 5px !important;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 0%) !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.textAreaTemplate {
  width: 97% !important;
  height: 98px;
  border: none;
  resize: none;
  overflow: hidden !important;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0.5em;
}

.css-2b8pcz>h2 {
  border-radius: 5px 5px 0 0;
}

.line {
  border-bottom: 1px solid #e8e8e8 !important;
  width: 98% !important;
  margin-left: 10px !important;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.detailsUserDivImage {
  margin-bottom: 20px;

  .img-modal {
    width: 174px;
    height: 98px;
  }
}

.divCard .ant-card-bordered {
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--icon-color);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
  }
}

.vignetteUpload {
  width: 100%;
  height: 100%;
}

.div_Url_diffusion {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 4%;

  span {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1%;
    font-family: "Inter", sans-serif;
  }


  .div_url_copier {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    line-height: 80px;

    Input {
      width: 100%;
      font-size: 14px;
      font-weight: normal;
      height: 32px !important;

    }

    .copy_icon {
      color: RGBA(0, 0, 0, 0.65) !important;
      font-size: 16px !important;
    }

    .iconTextArea {
      position: absolute;
      right: 10px;
      top: 15px;
      z-index: 99999999999;
    }

    Button {

      border: 1px solid #ccc;
      margin-bottom: 20%;
    }

  }

}

.input-feedback {
  color: rgb(201, 56, 56);
  margin-top: .19rem;
  position: relative;
  left: 15px;
}

.spn_theme {
  cursor: pointer;
  margin-left: 16px;
}


.divCard {
  width: 100%;
  margin-top: 1%;
  padding-bottom:0.5em;
  .ant-card-bordered {
    height: 85vh;
    width: 90%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width:700px) {
      padding-block-end: 0.2em !important;
    } 
  }

  .formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {

      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);

      .rc {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .formItem3 {
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0.65%;

    label {

      font-size: 14px;
      font-weight: 400;

      color: RGBA(0, 0, 0, 0.85);

      .requiredLabel {
        color: #F90001;
      }
    }

    .ant-col-8 {
      max-width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .multiselect .MuiSelect-select {
    padding-right: 0 !important;
  }


  .ant-col-22 {
    max-width: 100% !important;
  }

  .css-ahj2mt-MuiTypography-root {
    align-self: flex-start;
    font-weight: 500;
  }

  .InputLablDivUpload {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 32px;
  }

  .InputLablDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 1%;

    p,
    label {
      padding-left: 1%;
      color: black !important;
    }

    @media screen and (max-width: 767px) {
      width: 94%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;
      margin-left: 16px;
    }

  }

  @media screen and (max-width: 767px) {
    .css-ahj2mt-MuiTypography-root {
      margin-left: 0.3em;
    }
  }

  .ant-form-item-label {
    text-align: left !important;
    width: 100% !important;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

}

.css-1d6wzja-MuiButton-startIcon {
  margin-left: -7px !important;
}
.upload-img{
  margin-left: 0 !important;
}
.upload-imgUnique {
  display: flex !important;
  align-items: center !important;
flex-direction: column !important;
flex-wrap: wrap !important;
height: 100% !important;
justify-content: flex-start !important;
width: auto !important;
float: left !important;
margin-left: 1.5rem !important;

  div svg {
    height: 148px !important;
    width: 234px !important;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  span svg {
    height: 22px !important;
    width: 22px !important;
  }
  img {
    width: 180px;
    height: 110px;
  }

  label span {
    margin-right: 0 !important;
  }
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "";
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.dropzone-text {
  font-size: 100px !important;
  /* or something */
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 6px !important;
  border: 1px solid #E0E0E0;
}


.css-1cwnky8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 6px !important;
  overflow: hidden !important;
}

.required-star {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 0px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root:first-child {
  color: #B0B0B0 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
}



.css-uiqjn3-MuiPaper-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  opacity: 42%;
  border: none !important;
}




.css-6v688t-MuiModal-root-MuiDialog-root.MuiDialogContent-root {
  width: 70vh !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 250px !important;
}
.MuiList-padding, .MuiList-root, .MuiMenu-list ul{
  max-height: 250px !important;
  display: flex!important;
  flex-direction: column!important;
  list-style: none!important;
  margin: 0!important;
  outline: 0!important;
  padding: 8px!important;
  position: relative!important;
}
.css-14b29qc {
  gap: 1em !important
}
.lineActions{
  display: flex!important;
  flex-direction: row !important;
}



.button_ct {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: -29px !important;
}

.cadre_export{
  border: 1px solid rgb(224, 224, 224);
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0.5em;
  overflow: hidden;
  width: 97% !important;
}


.title_export {
  padding: 0.5em;
  font-family: "Inter", sans-serif;

}

.rested_icon,
.triggered_icon {
  padding: 0.5em;
  font-family: "Inter", sans-serif;

}

.embed_title {
  padding: 0.5em;
  font-family: "Inter", sans-serif;
}


.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  min-height: 45% !important;
}

.css-ypiqx9-MuiDialogContent-root {
  // padding: 34px 24px !important;
  overflow: hidden !important;
}


.css-1ty026z {
  overflow: hidden !important;
  padding: 21px 24px !important;
}

.seperator {
  width: 100%;
/*   margin-left: -24px; */
  padding: 0 !important;
  border-bottom: 1px solid #e8e8e8 !important;
}


.MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root {
  .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
    font-family: "Inter", sans-serif !important;
    white-space: pre-wrap !important;

  }

  .MuiTypography-root {
    font-family: "Inter", sans-serif !important;
  }
}








.css-14b29qc {
  padding: 0px !important;

}

.modal-confirm-content, .modal-confirm-title ,.css-tlc64q-MuiPaper-root-MuiDialog-paper{
  flex: none;
  flex-grow: 0;
  font-family: Roboto;
  font-style: normal;
  letter-spacing: .15px;
  order: 0;
  width: 100% !important ; 
}
.modal-confirm-title {
  white-space: pre-wrap;
}


.uploadmessage {
  color: #6d6d6d;
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 5.5em;
  margin-top: 0.4em;;
} 

.colomunHeaderUnique{
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: bolder !important;
  letter-spacing: 0.01071em !important;
  font-size: 14px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.toghetherAllArrows{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.canauxThemesGrid{
  background-color: rgba(0, 0, 0, 0.08);
  padding: 2px 6px;
  border-radius: 10px;
  white-space: nowrap;
}

.canauxThemesAligns{
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 0.7rem;
  justify-content: center;
}

.acceptedDimenssion{
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: rgb(210, 210, 210);
  display: block;
  width: 290px;
  margin-bottom: 1rem;
}

.maximum_upload_size {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: rgb(210, 210, 210);
  display: block;
  width: 290px;
  margin-top: 1rem;
}


.IconUpload {
  height: 23px !important;
  width: 23px !important;
}

.themes-details-modal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .themes-details-modal-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 1%;
    .themes-details-modal-title{
      font-size: 14px;
      font-weight: 700;
      font-style: normal;
      font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    }
    .themes-details-modal-value{
      font-size: 14px;
      font-weight: 400;
      border-radius: 6px;
      height: auto;
      padding: 11px;
      border: 1px solid #e0e0e0;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}

.modal_container{
  display: grid;
  align-items: center;
}
@media screen and (max-width:560px){
  .modal_export_row {
    display: grid !important;
    margin-inline-end: 12px;
  }
  .cadre_export,
.textAreaTemplate{
  font-size: 12px !important;
}

  .title_export{
    font-size: 12px !important;
    width:auto; 
    margin:auto;
    text-align:center;
  
  }

}
@media screen and (max-width:520px){
  .modal_export_row {
    padding: 1em 1em 1em .1em !important;
  }

.cadre_export,
.textAreaTemplate{
  font-size: 11px !important;
}
  .title_export{
    font-size: 10px !important;
    
  }
}

@media screen and (max-width:460px){
.cadre_export,
.textAreaTemplate{
  font-size: 10px !important;
}
  .title_export{
    font-size: 9px !important;
    margin-left: -1rem;

  }
}

@media screen and (max-width:400px){
  .modal_export_row{
    padding: 1em 0.1em 1em 0.1em !important;
  }
.cadre_export,
.textAreaTemplate{
  font-size: 9px !important;
}
.textAreaTemplate{
  height: 105px;
}
  .title_export{
    font-size: 8px !important;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    margin-left: -1rem;
     
  }
  .triggered_icon {
    padding: 0.1em;
  }
}


@media screen and (max-width:500px){
  .header_title{
    font-size: 17px !important;
  }
  .add_cancel_div{
    label{
      font-size: 13px !important;
      padding:5px 10px !important ;
    }
  }
 
}
@media screen and (max-width:450px){
  .add_cancel_div{
    label{
      font-size: 11px !important;
      padding:4px 9px !important ;
    }
  }
}
@media screen and (max-width:400px){
  .header_title{
    font-size: 14px !important;
  }
  .add_cancel_div{
    label{
      font-size: 11px !important;
      padding:3px !important ;
    }
  }
}

@media screen and (max-width:500px) {
  .pagination-text{
      display: none;
  }
}