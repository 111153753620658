@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-wk780c-MuiSvgIcon-root{
  cursor: pointer;
  margin-left: 0 !important;
}

.MuiDialogTitle-root{
  color: #fff;
  font-family: "Inter", sans-serif !important;
}
html,body {
  width: 100%;
  height: 99%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.add_div{
  box-sizing: border-box;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding:0 2%;
  margin: 12px;
  .profile_title{
    display: flex;
    gap:0.5em;
    align-items: center;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }
  .btnCancel{
    margin-right: 10px;
  }
}

.Filter{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1420px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    Button{
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }
    p{
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
    @media screen and (max-width: 1420px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 10%;
      .selectFilter{
        width: 100%;
        height: 40px !important;
        font-size: 16px;
        font-weight: 400;
      }
      .ant-select-selector{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
      }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    
    .rect_filter{
      width: 100%;
      height: 40px;
      box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.12);
      border-radius: 4px;
      .inputFilter{
        height: 100%;
      }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .centerFilter{
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      margin-left: 1.1vh;
    }
  }
}

.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding-top: 20px !important;
  white-space: pre-wrap !important;
}
@media screen and (max-width: 544px) {
  .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
    padding-top: 50px !important;
  }
  .css-1ty026z{
    margin-top: 45px;
  }
}
.css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  white-space: pre-wrap !important;
}


.divGlobalTable{
  .dataTable{
    margin-top: 15px;
    height: 80vh;
  }
  .dataTable::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}
.action{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.actionbtns{
  margin: 0 5px !important;
}

@media screen and (max-width: 768px) {
  .div_btn_add_cancel{
 
    margin-bottom: 6px;
  }

}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
}

.css-ltyecm-MuiSvgIcon-root {
  cursor: pointer;
}

.css-hlj6pa-MuiDialogActions-root {
  display: flex;
  gap:12px;
  margin-right: 5px;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset !important;
}

.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDiv{

    width: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
    justify-content: space-between;
    padding-bottom: 0.5%;
    .detailsUserDiv_spn2{
      background: #FFFFFF;
     border: 1px solid #E0E0E0;
      border-radius: 6px;
      height: 47px;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
      padding: 0.5em;
    }
  }
}

.div_btn_add_cancel{
  display: flex;
  flex-direction: row !important;
}


.ant-pagination-options{
  position: absolute;
  left: 0;
}
.ant-btn {
margin-top: 12px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .btnCancel.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    display: none;
  }
}

.rect_filter_delete{
  width: 100%;
  height: 40px;
  margin-left: 1%;
  box-shadow: 0px 4px 10px 2px rgba(0 ,0 ,0 ,0.12);
  border-radius: 4px;
}


.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator--sideRight {
  right: -23px !important;
}


.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader, .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell {
  -webkit-tap-highlight-color: transparent;
   padding: 0 !important ; 
  box-sizing: border-box;
}


.logo {
  height: 150px;
    width: 211px;
    margin-bottom: 10px;
}


.divGlobalTable_notFound {
  display: flex;
  justify-content: center;
}
.placeholder-color{
  color:#b3b3b3
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
  font-weight: bold !important;
}
.css-cc8tf1 {
  font-weight: bold !important;
}

.divLabel label,.InputLablDiv p, .InputLablDiv label, .InputLablDiv label *,.InputLablDivSelect label{
  font-size: 1rem !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 5px;
  padding-left: 5px;
}
.loading-container {
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header_title {
  display: flex !important;
  gap: 0.5em;
  white-space: nowrap;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: RGBA(0, 0, 0, 0.85);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

fieldset {
  border-color: rgba(224, 224, 224, 1) !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] .css-1spb1s5{
  margin-bottom: 0 !important;
}
.pagination-footer select{
  margin: 0 5px;
  height: 30px;
  width: 45px !important;
}